.tiers-program {
  padding-top: 20px;
  padding-bottom: 50px;
}

.tiers-program h2 {
  text-align: center;
  font-size: 30px;
}

.tiers-program article {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-row-gap: 0px;
}

.tiers-program article img {
  height: 50px;
  width: auto;
  margin: 5px;
}

.tiers-program article span:first-child {
  text-align: left;
}

.tiers-program article span:last-child {
  text-align: left;
}

.tiers-program article h3 {
  color: #67379a;
  font-weight: 600;
  margin: 0px 0 10px 0;
}

.tiers-program article p {
  font-size: 17px;
  font-weight: 500;
  margin-top: 0;
  color: #333;
  line-height: 1.75;
}

.program-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  padding: 50px 0;
}

#program-top {
  display: grid;
  grid-template-columns: 1fr 20fr;
  grid-row-gap: 0px;
}

.tiers-program .program-grid {
  padding: 50px 0 0 0 !important;
  grid-row-gap: 50px;
}

.programLine {
  margin-left: 10%;
  margin-right: 10%;
}

.spotlightArticles {
  /*background-color: #f3f4f5;*/
  text-align: center;
  background-size: 100% 100%;
  padding-bottom: 5vh;
}
.spotlightArticles h2 {
  text-align: center;
  font-size: 30px;
  padding-top: 30px;
}

.spotlightArticles .three-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  padding-top: 50px;
}

.spotlightArticles .extend {
  display: block;
  height: 100%;
}

.spotlight {
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.15),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  background-color: white;
  color: #333333;
  padding: 30px 30px 60px 30px;
  position: relative;
  transition: all 0.3s ease;
  text-align: left;
}

.spotlight:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.3),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
}

.spotlight img {
  height: 125px;
  width: auto;
  display: inline-block;
}

.spotlight h1 {
}

.spotlight p {
  font-family: 'Lato', sans-serif;
  display: block;
}

/* .company a {
        width: 100%;
        height: 100%;
    } */

.spotlight a {
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  color: #333333;
  position: absolute;
  bottom: 20px;
  font-size: 15px;
  margin: 0;
  transition: all 0.2s ease;
}

.spotlight a img {
  height: auto;
  position: relative;
  left: 10px;
  bottom: 1px;
  transition: all 0.2s ease;
}

.spotlight a:hover {
  color: rgb(73, 137, 223);
}

.spotlight a:hover img {
  height: auto;
  position: relative;
  left: 15px;
  bottom: 1px;
}

.company .companyTag {
  display: inline-block;
  color: white;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
}

.container {
  max-width: 1000px;
}

.no-overflow {
  overflow: hidden;
}

/* Track Record Section */
.trackRecord {
  background-color: #f3f4f5;
  background-size: cover;
  padding-top: 5vh;
  padding-bottom: 9vh;
}

.tr-container {
  padding: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.track-record-top {
  position: relative;
  background: #67379a;
  flex-flow: column nowrap;
  overflow: hidden;
  align-items: center;
  z-index: 5;
}

.trackRecord h3 {
  font-size: 30px;
  margin: 0;
}

.track-record-top h3 {
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

.tr-button {
  /* button styling */
  width: 325px;
  height: 55px;
  left: 503px;
  top: 478px;
  background: #ffffff;
  border-radius: 10px;
  border-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}

.tr-button p {
  /* text styling */
  line-height: 55px;
  display: inline-block;
  height: 55px;
  vertical-align: middle;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.trackRecord h2 {
  text-align: center;
  font-size: 30px;
  padding-bottom: 20px;
}

.track-info {
  width: 100%;
  text-align: left;
  font-weight: 400;
  color: #ffffff;
}

.track-record-bottom {
  position: relative;
  align-items: flex-start;
  left: 60px;
  right: -60px;
  top: -60px;
  padding-top: 100px;
  padding-right: 0;
  background: #ffffff;
  z-index: 0;
}

.tr-bottom-section {
  margin: 10px;
  top: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.tr-bottom-section img {
  display: block;
  max-height: 75px;
  margin: 15px 0;
}

#tr-img-greylock {
  max-height: 40px;
}

#tr-img-kp {
  max-height: 30px;
}

#tr-line {
  width: 0px;
  height: 213.5px;
  border: 2px solid #000000;
  align-self: center;
  margin: 0 20px;
}

.founders-flex {
  display: flex;
  flex-flow: column nowrap;
}

.teams-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

@media (max-width: 1034px) {
  .program-grid {
    grid-template-columns: 1fr;
  }

  .program-grid-mobile {
  }

  #program-top {
    grid-template-columns: 1fr 5fr;
  }

  .no-show-mobile {
    display: none;
  }

  .track-record-top {
    padding: 25px;
    margin-bottom: 30px;
  }

  .track-record-bottom {
    left: auto;
    right: auto;
    top: auto;
    padding-right: auto;
    padding: 25px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
  }

  .tr-bottom-section {
    margin: 30px 0;
  }

  .tr-button {
    width: 210px;
    height: 60px;
    left: 503px;
    top: 478px;
    background: #ffffff;
    border-radius: 10px;
    border-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tr-bottom-section img {
    max-height: 70px;
    margin: 15px 0;
  }

  #tr-img-greylock {
    max-height: 30px;
  }

  #tr-img-kp {
    margin-top: 30px;
    max-height: 30px;
  }
}
