.main-gallery {
  max-width: 1400px;
  height: 220px;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: auto;
  align-items: center;
  padding: 40px 20px;
}

.gallery-flex-item {
  box-sizing: border-box;
  margin: 0px 10px;
  padding: 0px;
}

.main-gallery img {
  box-sizing: border-box;
  object-fit: cover;
  height: 220px;
  border-width: 0;
  border-radius: 7px;
}

.no-overflow {
  overflow: hidden;
}

#copy {
  padding-top: 0;
}

#sidebar {
  /* Positioning on the page */
  margin: 20px;
  /* Positioning child elements inside this div */
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.circle {
  width: 150px;
  height: 150px;
  line-height: 100px;
  border-radius: 50%;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background: #67379a;
  margin: 10px 0;
}

.circle .text {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 43px;
  margin: 0;
}

.circle p.big {
  font-size: 38px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

p#eighty-plus {
  position: relative;
  right: -5px;
}

.circle p.small {
  font-size: 16px;
  text-align: center;
  margin: 0;
  width: auto;
}

.speakers {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.speaker-card-wrapper {
  box-sizing: border-box;
  display: block;
  color: black;
  flex-grow: 0;
  margin: 10px 0px;
}

.speakers-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.speakers-column {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  justify-content: flex-start;
  width: 400px;
}

.speaker-card {
  color: black;
  display: block;
  height: 100%;
  padding: 5px;
}

.speaker-card-header {
  display: flex;
  align-items: center;
  line-height: 24px;
}

.speaker-card-header img {
  height: 100px;
  margin-right: 16px;
  vertical-align: middle;
  box-sizing: border-box;
  object-fit: cover;
  border-width: 0;
  border-radius: 7px;
}

.speaker-card-description {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.speaker-card-description h2 {
  display: inline;
  font-size: 18px;
  line-height: 19.2px;
}

.speaker-card-description h3 {
  display: inline;
  font-size: 16px;
  font-weight: 300;
}

#overview {
  display: flex;
  flex-flow: row nowrap;
}

#coretasks {
  display: flex;
  flex-flow: row nowrap;
}

@media (max-width: 1034px) {
  .main-gallery {
    width: 100vw;
    padding: 40px 0;
    margin: 0;
    justify-content: flex-start;
  }

  #overview {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  #sidebar {
    width: 100vw;
    flex-flow: row nowrap;
    justify-content: space-around;
  }

  .circle {
    margin: 0 10px;
  }

  .main-gallery img {
    height: 250px;
  }

  .speakers-column {
    margin: auto;
  }
  .speaker-card-wrapper {
    margin: 5px 0px;
  }
}
