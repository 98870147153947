.timeline {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
}

.timeline article {
  text-align: center;
  justify-content: center;
  padding: 20px;
}

.timeline article div {
  background-color: #67379a;
  border-radius: 100px;
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.timeline article div img {
  width: 30px;
  position: relative;
  top: 17px;
}

.timeline article h2 {
  font-size: 18px;
  margin-bottom: 0;
}

.timeline article p {
  padding: 0;
  margin: 0;
}

.timeline span {
  display: inline-block;
  position: relative;
  text-align: center;
  position: relative;
  top: 45px;
}

.timeline span img {
  width: 20px;
}

@media (max-width: 1034px) {
  .timeline span {
    display: none;
  }
}

@media (max-width: 561px) {
    .timeline {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
  }
