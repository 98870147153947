.peopleGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 30px;
  justify-content: center;
}

.person {
  display: inline-block;
  margin: 0;
  text-align: center;
  margin: 0 auto;
}

.person img {
  width: 65%;
  border-radius: 7px;
}

.person h2 {
  font-size: 23px;
  margin: 10px 0;
}

.person h3 {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 600;
  margin: 10px 0;
}

.person .links {
  display: block;
}

.person .links img {
  width: 17px;
  opacity: 0.6;
  transition: all 0.3s ease;
  margin: 0 5px;
  border-radius: 0px;
}

.person .links img:hover {
  opacity: 0.8;
}

.gray {
  background-color: #F3F4F5;
}

#internal-alumni{
  padding-bottom: 32px;
}

.alumni-marquee-wrapper {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 20px;
  padding-top: 20px;
  width: 100%;
}

.alumni-cards-marquee {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  justify-content: center;
  scroll-behavior: auto;
}

.alumni-cards-row {
  display: flex;
  flex-direction: row;
  position: relative;
  scroll-behavior: auto;
  overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.alumni-cards-row::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.alumni-cards-row {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.alumnus-card-wrapper {
  box-sizing: border-box;
  display: block;
  color: black;
  flex-grow: 0;
  flex-shrink: 0;
  width: 280px;
  padding-right: 8px;
  padding-left: 8px;
}

.alumnus-card {
  box-sizing: border-box;
  background-color: white;
  border-color: #eee;
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  color: black;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  padding: 16px 18px;
}

.alumnus-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  line-height: 24px;
}

.alumnus-card-header img {
  height: 60px;
  line-height: 24px;
  max-width: 64px;
  margin-right: 16px;
  vertical-align: middle;
  border-radius: 7px;
}

.alumnus-card-header h2 {
  font-size: 16px;
  line-height: 19.2px;
}

.alumnus-card-description h3 {
  font-size: 12px;
  font-size: 14px;
  font-weight: 300;
  margin: auto;
}

@media (max-width: 1034px) {
  .peopleGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
